import React, { useState, useEffect, useRef } from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Loader } from 'smart-react';
import { Card } from '@progress/kendo-react-layout';
import { Button } from '@progress/kendo-react-buttons';
import { ChartImageComponent, GridComponent, PieChartComponent } from '../../../Utils/FormatMessage/FormatMessage'; // Importing the new components
import { buildNotification, GenerateNotification } from 'smart-react';
import {
  EVENTS_DATA_TYPES,
  NOTIFICATION_TYPES,
} from '../../../constants/eventDataTypes';
import { listAssistants } from '../Services/AiAssistantService';
import {
  createThread,
  sendMessage,
  createRun,
  listMessages,
  retrieveRun, handleOpenAIStream,
} from '../Services/OpenAIChatService';
import {
  deleteFile,
  uploadFile,
} from '../Services/FileService';
import { sendBYMessage, BYLogin } from '../Services/BlueYonderChatService';
import FormatMessageContent from '../../../Utils/Filters/FormatMessageContent';
import { Switch } from '@progress/kendo-react-inputs';
import { sendQuestion, handleSmartRagStream } from '../Services/SmartRagChatService';
import generateSessionKeyFunction from '../../../Utils/Common/generateSessionKey';

/**
 * Component for managing chat with AI assistants.
 * @param {Object} props - Component props.
 * @param {string} props.assistantId - The ID of the assistant.
 * @param {string} props.assistantName - The name of the assistant.
 * @returns {JSX.Element} SmartChat component.
 */
const SmartChat = ({ assistantId, assistantName }) => {
  const [isStreamingResponse, setIsStreamingResponse] = useState(false); // State for including files in the first thread
  const [isLoader, setIsLoader] = useState(false);
  const [sending, setSending] = useState(false);
  const [assistants, setAssistants] = useState([]);
  const [selectedAssistant, setSelectedAssistant] = useState(null);
  const [threadId, setThreadId] = useState('');
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [runId, setRunId] = useState('');
  const [runStatus, setRunStatus] = useState('');
  const chatWindowRef = useRef(null);
  const [showPromptOverlay, setShowPromptOverlay] = useState(false); // State to control prompt overlay
  const [prompts, setPrompts] = useState([]); // State to store prompts
  const [sessionKey, setSessionKey] = useState(null); // State to store session key
  const [attachments, setAttachments] = useState([]);
  const [attachmentDetails, setAttachmentDetails] = useState({});
  const [BYLoginSession, setBYLoginSession] = useState(false);

  /**
   * Resets the chat to its initial state.
   */
  const handleResetChat = () => {
    setIsLoader(false);
    setSending(false);
    setSelectedAssistant({ name: 'Please Select', id: '' });
    setThreadId('');
    setMessages([]);
    setNewMessage('');
    setRunId('');
    setRunStatus('');
    setSessionKey(null);
    setShowPromptOverlay(false);
    setIsStreamingResponse(false);
    setAttachments([]);
    setAttachmentDetails({});
  };

  useEffect(() => {
    if (assistantId) {
      setSelectedAssistant({ id: assistantId, name: assistantName });
    } else {
      fetchAssistants();
    }
  }, [assistantId, assistantName]);

  useEffect(() => {
    if (threadId && runId && runStatus !== 'completed') {
      const intervalId = setInterval(fetchRunStatus, 3000);
      return () => clearInterval(intervalId);
    }
  }, [threadId, runId, runStatus]);

  useEffect(() => {
    // Check if the selected assistant is of 'other' type and session key is not set
    if (selectedAssistant?.type !== 'OpenAI' && !sessionKey) {
      generateSessionKey();
    }
  }, [selectedAssistant, sessionKey]);

  useEffect(() => {
    scrollToBottom();
    console.log(messages);
  }, [messages]);

  const handleStreamingResponseCheck = () => {
    setIsStreamingResponse(!isStreamingResponse); // Toggle includeFiles state
  };
  /**
   * Fetches the run status.
   */
  const fetchRunStatus = async () => {
    try {
      const run = await retrieveRun(threadId, runId);
      setRunStatus(run.status);
    } catch (error) {
      GenerateNotification(
        buildNotification({
          title: 'Error fetching run status: ' + error,
          description: '',
          style: 'error',
        }),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
      );
    }
  };

  /**
   * Fetches the list of assistants.
   */
  const fetchAssistants = async () => {
    setIsLoader(true);
    try {
      const response = await listAssistants(-1);
      if (response.isSuccess) {
        setAssistants(response.data);
        if (response.data.length == 1) {
          setSelectedAssistant(response.data[0]);
        } else {
          setSelectedAssistant({ name: 'Please Select', id: '' });
        }
      } else {
        GenerateNotification(
          buildNotification({
            title: 'Error fetching assistants',
            description: '',
            style: 'error',
          }),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
        );
      }
    } catch (error) {
      GenerateNotification(
        buildNotification({
          title: 'Error fetching assistants: ' + error,
          description: '',
          style: 'error',
        }),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
      );
    } finally {
      setIsLoader(false);
    }
  };

  /**
   * Handles selection of an assistant.
   * @param {Object} event - Event object.
   */
  const handleAssistantSelect = async (event) => {
    handleResetChat();
    const { value } = event.target;
    setSelectedAssistant(value);
    if (value.type === 'OpenAI') {
      fetchPrompts(value);
    }
  };

  /**
   * Creates a new run for the thread.
   */
  const createNewRun = async (threadId, assistantId) => {
    try {
      const { id } = await createRun(threadId, assistantId);
      setRunId(id);
      await waitForCompletion(threadId, id);
    } catch (error) {
      GenerateNotification(
        buildNotification({
          title: 'Error creating new run: ' + error,
          description: '',
          style: 'error',
        }),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
      );
    }
  };

  /**
   * Waits for the thread run to complete.
   */
  const waitForCompletion = async (threadId, runId) => {
    while (true) {
      const run = await retrieveRun(threadId, runId);
      if (run.status === 'completed') {
        setIsLoader(false);
        setSending(false);
        await fetchThreadMessages();
        break;
      }
      await new Promise((resolve) => setTimeout(resolve, 3000));
    }
  };

  /**
   * Handles sending of a message.
   */
  const handleAssistantNotSelected = () => {
    if (!selectedAssistant || selectedAssistant.id === '') {
      GenerateNotification(
        buildNotification({
          title: 'Please select an assistant!',
          description: '',
          style: 'error',
        }),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
      );
      return true;
    }
    return false;
  };

  /**
   * Handles the message flow for OpenAI assistants.
   * @param {string} [prompt=''] - Prompt for the message.
   */
  const handleOpenAIMessageFlow = async (prompt = '') => {
    if (handleAssistantNotSelected()) return;

    const trimmedNewMessage = newMessage.trim();
    const trimmedPrompt = Array.isArray(prompt) ? prompt[0] : prompt.trim();

    if (trimmedNewMessage.length === 0 && trimmedPrompt.length === 0) return;

    try {
      setShowPromptOverlay(false);

      if (!threadId) {
        setSending(true);
        const messageText = trimmedNewMessage ? trimmedNewMessage : trimmedPrompt;
        setMessages([...messages, { role: 'user', text: messageText }]);
        setNewMessage('');
        const { id } = await createThread('user', messageText);
        setThreadId(id);
        if (isStreamingResponse) {
          await startOpenAIStream(id, selectedAssistant.assistant_id);
        } else {
          await createNewRun(id, selectedAssistant.assistant_id);
          await fetchThreadMessages(id);
        }
      } else {
        await sendMessage(threadId, newMessage, {}, 'user', attachments);
        setSending(true);
        setNewMessage('');
        setMessages([...messages, { role: 'user', text: trimmedNewMessage }]);
        if (isStreamingResponse) {
          await startOpenAIStream(threadId, selectedAssistant.assistant_id);
        } else {
          await createNewRun(threadId, selectedAssistant.assistant_id);
        }
      }
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setSending(false);
    }
  };

  /**
   * Start Stream
   * @param threadId
   * @param assistantId
   */
  const startOpenAIStream = async (threadId, assistantId) => {
    try {
      // Create a run with stream option set to true
      const runResponse = await createRun(threadId, assistantId, true);
      // Call the handleStream function with the runResponse
      await handleOpenAIStream(runResponse, setMessages);
    } catch (error) {
      console.error('Error starting stream:', error);
    }
  };
  /**
   * Handles Blue Yonder Assistant Flow
   */
  const handleBlueYonderAssistantMessageFlow = async () => {
    if (handleAssistantNotSelected()) return;

    const trimmedNewMessage = newMessage.trim();
    if (trimmedNewMessage.length === 0) return;

    setMessages(prevMessages => [...prevMessages, { role: 'user', text: newMessage }]);
    setNewMessage('');
    setSending(true);

    if (BYLoginSession === false) {
      const BYLoginResponse = await BYLogin(JSON.parse(selectedAssistant?.data));
      if (BYLoginResponse.status_code === 200) {
        setBYLoginSession(true);
      }
    }
    const data = {
      command: newMessage,
      session_key: JSON.parse(selectedAssistant?.data).session_key,
    };
    const response = await sendBYMessage(data);
    console.log(response);
    setSending(false);
    if (response?.status_code === 200) {
      setMessages(prevMessages => [...prevMessages, {
        role: 'assistant',
        text: response?.message,
        type: response?.type,
        data: response?.payload,
        attachment: response?.attachemnt,
      }]);
      setSending(false);
    } else {
      GenerateNotification(
        buildNotification({
          title: response.message,
          description: '',
          style: 'error',
        }),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
      );
    }
    console.log(messages);


  };

  /**
   * Handles Smart AI Assistant Flow
   */
  const handleSmartAIAssistantMessageFlow = async () => {
    if (handleAssistantNotSelected()) return;

    const trimmedNewMessage = newMessage.trim();
    if (trimmedNewMessage.length === 0) return;

    setMessages(prevMessages => [...prevMessages, { role: 'user', text: newMessage }]);
    setNewMessage('');
    setSending(true);

    let data = {
      question: newMessage,
      session_key: sessionKey,
      stream: isStreamingResponse,
    };

    try {
      if (isStreamingResponse) {
        await handleSmartRagStream(JSON.parse(selectedAssistant.data).guid, data, setMessages);
      } else {
        const response = await sendQuestion(JSON.parse(selectedAssistant.data).guid, data);
        console.log(response);
        if (response?.isSuccess) {
          setMessages(prevMessages => [...prevMessages, {
            role: 'assistant',
            text: response.data.answer,
          }]);
        } else {
          GenerateNotification(
            buildNotification({
              title: response.message,
              description: '',
              style: 'error',
            }),
            NOTIFICATION_TYPES.APP,
            EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
          );
        }
      }
    } catch (error) {
      GenerateNotification(
        buildNotification({
          title: 'Error handling message flow: ' + error.message,
          description: '',
          style: 'error',
        }),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
      );
    } finally {
      setSending(false);
    }
  };
  /**
   * Handles message sending when the Enter key is pressed.
   * @param prompt
   */
  const handleMessageSend = async (prompt = '') => {
    if (selectedAssistant?.type === 'OpenAI') {
      await handleOpenAIMessageFlow(prompt);
    } else if (selectedAssistant?.type === 'SmartAI') {
      await handleSmartAIAssistantMessageFlow(prompt);
    } else if (selectedAssistant?.type === 'BlueYonder') {
      await handleBlueYonderAssistantMessageFlow(prompt);
    }
  };

  /**
   * Fetches the thread messages list.
   * @param {string} [id] - ID of the thread.
   */
  const fetchThreadMessages = async (id) => {
    try {
      if (threadId || id) {
        const threadMessages = await listMessages(threadId || id);
        setMessages(threadMessages.reverse());
      }
    } catch (error) {
      // Handle error
    }
  };

  /**
   * Scrolls to the bottom of the chat window.
   */
  const scrollToBottom = () => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  };

  /**
   * Submits the message on key press (Enter).
   * @param {Object} event - Event object.
   */
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (!sending) {
        handleMessageSend();
      }
    }
  };

  /**
   * Handles the selection of a prompt and hides the prompt overlay.
   * @param {string} prompt - Selected prompt.
   */
  const handlePromptSelection = (prompt) => {
    handleMessageSend(prompt);
    setShowPromptOverlay(false);
  };

  /**
   * Fetches prompts based on assistant instructions and updates the state.
   * @param promptsData
   */
  const fetchPrompts = async (promptsData) => {
    setIsLoader(true);
    if (promptsData && promptsData?.prompts) {
      const shuffledPrompts = promptsData.prompts.map(item => item.prompt).sort(() => Math.random() - 0.5);
      const randomPrompts = shuffledPrompts.slice(0, 4);
      setPrompts(randomPrompts);
      setShowPromptOverlay(true);
    }
    setIsLoader(false);
  };

  /**
   * Generate or retrieve session key only when other type assistant message flow is called
   */
  const generateSessionKey = async () => {
    try {
      // Code to generate or retrieve session key
      const key = generateSessionKeyFunction(); // Use the UUID generation function
      setSessionKey(key);
    } catch (error) {
      console.error('Error generating session key:', error);
    }
  };

  /**
   * Handle File Change
   */
  const handleFileChange = async (event) => {
    setIsLoader(true);
    const file = event.target.files[0];
    if (file) {
      // Check file extension
      const allowedExtensions = ['pdf', 'jsonl'];
      const fileExtension = file.name.split('.').pop().toLowerCase();

      if (!allowedExtensions.includes(fileExtension)) {
        // Handle invalid file extension
        GenerateNotification(
          buildNotification({
            title: 'Invalid file type',
            description: `Only .pdf and .jsonl files are allowed.`,
            style: 'error',
          }),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
        );
        setIsLoader(false);
        return;
      }
      try {
        const response = await uploadFile(file);
        setAttachments([{ file_id: response.id, tools: [{ 'type': 'file_search' }] }]);
        setAttachmentDetails([{ file_id: response.id, file_name: file.name }]);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
    setIsLoader(false);
  };

  /**
   * Handle File Delete
   */
  const handleFileDelete = async (fileId) => {
    setIsLoader(true);
    try {
      // Call the deleteFile function with the file ID
      await deleteFile(fileId);
      // Remove the deleted file from the attachments state
      setAttachments(attachments.filter((attachment) => attachment.file_id !== fileId));
      setAttachments(attachmentDetails.filter((attachment) => attachment.file_id !== fileId));
    } catch (error) {
      console.error('Error deleting file:', error);
    }
    setIsLoader(false);
  };

  return (
    <div className='chat-container'>
      <div className='header !k-mb-0'>
        {assistantName ? `Chat With ${assistantName}` : 'Chat APP'}
      </div>

      {assistantId == null && (
        <Card className='assistant-selection'>
          <div className='k-d-flex k-justify-content-between'>
            <h5>
              Select Assistant
              {!sending && (
                <span
                  className='k-button-icon k-font-icon k-i-arrow-rotate-cw-small !k-font-size-xl delete-button k-cursor-pointer reset-button k-ml-1'
                  onClick={handleResetChat}
                />
              )}
            </h5>
            {(selectedAssistant?.type === 'OpenAI' || selectedAssistant?.type === 'SmartAI') && (
              <div className='streaming-response-switch k-mt-2 k-mb-2 k-float-right'>
                <span className='k-mr-2'>Streaming</span>
                <Switch checked={isStreamingResponse} onChange={handleStreamingResponseCheck} />
              </div>
            )}
          </div>
          <DropDownList
            data={assistants}
            textField='name'
            dataItemKey='id'
            defaultValue={selectedAssistant}
            onChange={handleAssistantSelect}
            value={selectedAssistant}
            placeholder='Select Assistant'
            disabled={sending}
          />
        </Card>
      )}

      <Card className='chat-box'>
        {isLoader && (
          <div className='loader-wrapper'>
            <Loader />
          </div>
        )}
        <div className='chat-window' ref={chatWindowRef}>
          {messages && messages.slice(0).map((message, index) => (
            <div
              key={index}
              className={`message ${message.role === 'user' ? 'user-message' : 'assistant-message'} ${message.type ? ` otherdata ${message.type}` : ''}`}
            >
              {message.type === 'Grid' ? (
                // Render table if the type is Grid
                <>
                  <p>{message?.text}</p>
                  <GridComponent metadata={message?.data?.metadata} values={message?.data?.values} />
                </>
              ) : message.type === 'Pie_Chart' ? (
                // Render pie chart if the type is PieChart
                <>
                  <p>{message?.text}</p>
                  <PieChartComponent metadata={message?.data?.metadata} values={message?.data?.values} />
                </>
              ) : message.type === 'Chart' ? (
                <>
                  <p>{message?.text}</p>
                  <ChartImageComponent imageContent={message?.attachment?.content} imageType={message?.attachment?.type} />
                </>
              ) : (
                // Otherwise, render the message content
                <FormatMessageContent markdownContent={message.text} />
              )}
            </div>
          ))}
          {showPromptOverlay && (
            <div className='prompt-cards'>
              {prompts.map((prompt, index) => (
                <div
                  key={index}
                  className='prompt-card'
                  onClick={() => handlePromptSelection(prompt)}
                >
                  <span>{prompt}</span>
                  <button
                    className='delete-button k-float-right k-cursor-pointer'
                    onClick={() => handlePromptSelection(prompt)}
                  >
                    <span className='fas fa-paper-plane' />
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className='loader-div'>
          {sending && <div className='dots k-mb-5' />}
        </div>
        <div className='message-input'>
          <input
            type='text'
            value={newMessage}
            onKeyPress={handleKeyPress}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder='Type your message...'
          />
          {selectedAssistant?.type === 'OpenAI' && (
            <>
              <input
                type='file'
                className='k-d-none'
                onChange={handleFileChange}
                id='file-input'
                accept='.pdf,.jsonl'
              />
              <label htmlFor='file-input' className='file-input-label k-cursor-pointer ai-button-primary'>
                <span className='k-font-icon k-i-attachment' />
              </label>
            </>
          )}
          <Button
            className='ai-button-primary'
            onClick={() => {
              handleMessageSend();
            }}
            disabled={sending}
          >
            {sending ? 'Sending...' : 'Send'}{' '}
            {!sending && <span className='fas fa-paper-plane k-mb-1 k-ml-1' />}
          </Button>
        </div>
      </Card>
      {attachmentDetails && attachments.length > 0 && (
        <div className='attachments'>
          {attachmentDetails.map((attachment, index) => (
            <div key={index} className='attachment k-display-flex'>
              <p className='!k-m-0 k-font-size-sm'>{attachment.file_name}</p>
              <button
                className='delete-button k-cursor-pointer'
                onClick={() => handleFileDelete(attachment.file_id)}
              >
                <span className='fas fa-times' />
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SmartChat;
