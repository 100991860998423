import React, { useState, useEffect, useRef } from 'react';
import { Card } from '@progress/kendo-react-layout';
import { Button } from '@progress/kendo-react-buttons';
import { ChartImageComponent, GridComponent, PieChartComponent } from '../../../Utils/FormatMessage/FormatMessage'; // Importing the new components
import { buildNotification, GenerateNotification, Loader } from 'smart-react';
import {
  EVENTS_DATA_TYPES,
  NOTIFICATION_TYPES,
} from '../../../constants/eventDataTypes';
import { listAssistantsByTenantId } from '../Services/AiAssistantService';
import {
  createThread,
  sendMessage,
  createRun,
  listMessages,
  retrieveRun, handleOpenAIStream,
} from '../Services/OpenAIChatService';
import { sendBYMessage, BYLogin } from '../Services/BlueYonderChatService';
import FormatMessageContent from '../../../Utils/Filters/FormatMessageContent';
import { Switch } from '@progress/kendo-react-inputs';
import { useParams } from 'react-router';
import { sendQuestion, handleSmartRagStream } from '../Services/SmartRagChatService';

/**
 * Component for managing chat with AI assistants.
 * @returns {JSX.Element} SmartChat component.
 */
const SmartBot = () => {
  const [isLoader, setIsLoader] = useState(false);
  const [sending, setSending] = useState(false);
  const [assistants, setAssistants] = useState([]);
  const [selectedAssistant, setSelectedAssistant] = useState(null);
  const [threadId, setThreadId] = useState('');
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [runId, setRunId] = useState('');
  const [runStatus, setRunStatus] = useState('');
  const chatWindowRef = useRef(null);
  const [showPromptOverlay, setShowPromptOverlay] = useState(false); // State to control prompt overlay
  const [prompts, setPrompts] = useState([]); // State to store prompts
  const [sessionKey, setSessionKey] = useState(null); // State to store session key
  const [streamingResponse, setStreamingResponse] = useState(false);
  const [BYLoginSession, setBYLoginSession] = useState(false);

  /**
   * Resets the chat to its initial state.
   */
  const handleResetChat = () => {
    setIsLoader(false);
    setSending(false);
    setThreadId('');
    setMessages([]);
    setNewMessage('');
    setRunId('');
    setRunStatus('');
    setSessionKey(null);
    setShowPromptOverlay(false);
    setStreamingResponse(false);
  };

  // Get the id parameter from the URL
  const { id } = useParams();

  useEffect(() => {
    // Function to decode base64 encoded string
    const decodeBase64 = (base64String) => {
      return atob(base64String);
    };
    // Decode the id parameter
    if (id) {
      const decodedInfo = decodeBase64(id);
      if (JSON.parse(decodedInfo)?.tenant_id) {
        const assistantsByTenantId = fetchAssistantsWithTenantId(JSON.parse(decodedInfo)?.tenant_id);
      } else {
        setSelectedAssistant(JSON.parse(decodedInfo));
      }
    }
  }, [id]);


  useEffect(() => {
    if (threadId && runId && runStatus !== 'completed') {
      const intervalId = setInterval(fetchRunStatus, 3000);
      return () => clearInterval(intervalId);
    }
  }, [threadId, runId, runStatus]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleStreamingResponse = () => {
    setStreamingResponse(!streamingResponse); // Toggle includeFiles state
  };
  /**
   * Fetches the run status.
   */
  const fetchRunStatus = async () => {
    try {
      const run = await retrieveRun(threadId, runId);
      setRunStatus(run.status);
    } catch (error) {
      GenerateNotification(
        buildNotification({
          title: 'Error fetching run status: ' + error,
          description: '',
          style: 'error',
        }),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
      );
    }
  };

  /**
   * Fetches the list of assistants.
   */
  const fetchAssistantsWithTenantId = async ($id) => {
    setIsLoader(true);
    try {
      const response = await listAssistantsByTenantId($id);
      if (response.isSuccess) {
        setAssistants(response.data);
        setSelectedAssistant(response.data[0]);
      } else {
        GenerateNotification(
          buildNotification({
            title: 'Error fetching assistants',
            description: '',
            style: 'error',
          }),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
        );
      }
    } catch (error) {
      GenerateNotification(
        buildNotification({
          title: 'Error fetching assistants: ' + error,
          description: '',
          style: 'error',
        }),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
      );
    } finally {
      setIsLoader(false);
    }
  };

  /**
   * Handles selection of an assistant.
   * @param {Object} event - Event object.
   */
  const handleAssistantSelect = async (event) => {
    const assistant = assistants.find(assistant => assistant.id === parseInt(event));
    handleResetChat();
    setSelectedAssistant(assistant);
    if (assistant.type === 'OpenAI') {
      await fetchPrompts(assistant);
    }
  };

  /**
   * Creates a new run for the thread.
   */
  const createNewRun = async (threadId, assistantId) => {
    try {
      const { id } = await createRun(threadId, assistantId);
      setRunId(id);
      await waitForCompletion(threadId, id);
    } catch (error) {
      GenerateNotification(
        buildNotification({
          title: 'Error creating new run: ' + error,
          description: '',
          style: 'error',
        }),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
      );
    }
  };

  /**
   * Waits for the thread run to complete.
   */
  const waitForCompletion = async (threadId, runId) => {
    while (true) {
      const run = await retrieveRun(threadId, runId);
      if (run.status === 'completed') {
        setIsLoader(false);
        setSending(false);
        await fetchThreadMessages();
        break;
      }
      await new Promise((resolve) => setTimeout(resolve, 3000));
    }
  };

  /**
   * Handles sending of a message.
   */
  const handleAssistantNotSelected = () => {
    if (!selectedAssistant || selectedAssistant.id === '') {
      GenerateNotification(
        buildNotification({
          title: 'Please select an assistant!',
          description: '',
          style: 'error',
        }),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
      );
      return true;
    }
    return false;
  };


  const handleOpenAIMessageFlow = async (prompt = '') => {
    if (handleAssistantNotSelected()) return;

    const trimmedNewMessage = newMessage.trim();
    const trimmedPrompt = Array.isArray(prompt) ? prompt[0] : prompt.trim();

    if (trimmedNewMessage.length === 0 && trimmedPrompt.length === 0) return;

    try {
      setShowPromptOverlay(false);

      if (!threadId) {
        setSending(true);
        const messageText = trimmedNewMessage ? trimmedNewMessage : trimmedPrompt;
        setMessages([...messages, { role: 'user', text: messageText }]);
        setNewMessage('');
        const { id } = await createThread('user', messageText);
        setThreadId(id);
        if (streamingResponse) {
          await startStream(id, selectedAssistant.assistant_id);
        } else {
          await createNewRun(id, selectedAssistant.assistant_id);
          await fetchThreadMessages(id);
        }
      } else {
        await sendMessage(threadId, newMessage);
        setSending(true);
        setNewMessage('');
        setMessages([...messages, { role: 'user', text: trimmedNewMessage }]);
        if (streamingResponse) {
          await startStream(threadId, selectedAssistant.assistant_id);
        } else {
          await createNewRun(threadId, selectedAssistant.assistant_id);
        }
      }
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setSending(false);
    }
  };

  const startStream = async (threadId, assistantId) => {
    try {
      // Create a run with stream option set to true
      const runResponse = await createRun(threadId, assistantId, true);
      // Call the handleStream function with the runResponse
      await handleOpenAIStream(runResponse, setMessages);
    } catch (error) {
      console.error('Error starting stream:', error);
    }
  };

  const handleSmartAIAssistantMessageFlow = async () => {
    if (handleAssistantNotSelected()) return;

    const trimmedNewMessage = newMessage.trim();
    if (trimmedNewMessage.length === 0) return;

    setMessages(prevMessages => [...prevMessages, { role: 'user', text: newMessage }]);
    setNewMessage('');
    setSending(true);

    const data = {
      question: newMessage,
      session_key: sessionKey,
      stream: streamingResponse,
    };

    try {
      if (streamingResponse) {
        await handleSmartRagStream(JSON.parse(selectedAssistant.data).guid, data, setMessages);
      } else {
        const response = await sendQuestion(JSON.parse(selectedAssistant.data).guid, data);
        if (response?.isSuccess) {
          setMessages(prevMessages => [...prevMessages, {
            role: 'assistant',
            text: response.data.answer,
          }]);
        } else {
          GenerateNotification(
            buildNotification({
              title: response.message,
              description: '',
              style: 'error',
            }),
            NOTIFICATION_TYPES.APP,
            EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
          );
        }
      }
    } catch (error) {
      GenerateNotification(
        buildNotification({
          title: 'Error handling message flow: ' + error.message,
          description: '',
          style: 'error',
        }),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
      );
    } finally {
      setSending(false);
    }
  };

  const handleBlueYonderAssistantMessageFlow = async () => {
    if (handleAssistantNotSelected()) return;

    const trimmedNewMessage = newMessage.trim();
    if (trimmedNewMessage.length === 0) return;

    setMessages(prevMessages => [...prevMessages, { role: 'user', text: newMessage }]);
    setNewMessage('');
    setSending(true);

    if (BYLoginSession === false) {
      const BYLoginResponse = await BYLogin(JSON.parse(selectedAssistant?.data));
      if (BYLoginResponse.status_code === 200) {
        setBYLoginSession(true);
      }
    }
    const data = {
      command: newMessage,
      session_key: JSON.parse(selectedAssistant?.data).session_key,
    };
    const response = await sendBYMessage(data);
    setSending(false);
    if (response?.status_code === 200) {
      setMessages(prevMessages => [...prevMessages, {
        role: 'assistant',
        text: response?.message,
        type: response?.type,
        data: response?.payload,
        attachment: response?.attachemnt,
      }]);
      setSending(false);
    } else {
      GenerateNotification(
        buildNotification({
          title: response.message,
          description: '',
          style: 'error',
        }),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION,
      );
    }
    console.log(messages);


  };

  const handleMessageSend = async (prompt = '') => {
    if (selectedAssistant?.type === 'OpenAI') {
      await handleOpenAIMessageFlow(prompt);
    } else if (selectedAssistant?.type === 'SmartAI') {
      await handleSmartAIAssistantMessageFlow(prompt);
    } else if (selectedAssistant?.type === 'BlueYonder') {
      await handleBlueYonderAssistantMessageFlow(prompt);
    }
  };

  /**
   * Fetches the thread messages list.
   * @param {string} [id] - ID of the thread.
   */
  const fetchThreadMessages = async (id) => {
    try {
      if (threadId || id) {
        const threadMessages = await listMessages(threadId || id);
        setMessages(threadMessages.reverse());
      }
    } catch (error) {
      // Handle error
    }
  };

  /**
   * Scrolls to the bottom of the chat window.
   */
  const scrollToBottom = () => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  };

  /**
   * Submits the message on key press (Enter).
   * @param {Object} event - Event object.
   */
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (!sending) {
        handleMessageSend();
      }
    }
  };

  /**
   * Handles the selection of a prompt and hides the prompt overlay.
   * @param {string} prompt - Selected prompt.
   */
  const handlePromptSelection = (prompt) => {
    handleMessageSend(prompt);
    setShowPromptOverlay(false);
  };

  /**
   * Fetches prompts based on assistant instructions and updates the state.
   * @param promptsData
   */
  const fetchPrompts = async (promptsData) => {
    setIsLoader(true);
    if (promptsData && promptsData?.prompts) {
      const shuffledPrompts = promptsData.prompts.map(item => item.prompt).sort(() => Math.random() - 0.5);
      const randomPrompts = shuffledPrompts.slice(0, 4);
      setPrompts(randomPrompts);
      setShowPromptOverlay(true);
    }
    setIsLoader(false);
  };
  const generateSessionKeyFunction = () => {
    const uuid = () => {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = Math.random() * 16 | 0,
          v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    };

    return uuid();
  };

// Generate or retrieve session key only when other type assistant message flow is called
  const generateSessionKey = async () => {
    try {
      // Code to generate or retrieve session key
      const key = generateSessionKeyFunction(); // Use the UUID generation function
      setSessionKey(key);
    } catch (error) {
      console.error('Error generating session key:', error);
    }
  };

  useEffect(() => {
    // Check if the selected assistant is of 'other' type and session key is not set
    if (selectedAssistant?.type !== 'OpenAI' && !sessionKey) {
      generateSessionKey();
    }
  }, [selectedAssistant, sessionKey]);

  return (
    <div className='bot-chat-container k-bg-white'>
      {isLoader && (
        <Loader />
      )}
      {selectedAssistant?.name && (
        <Card className='assistant-selection k-mt-1'>
          <div className='k-d-flex k-justify-content-between'>
            <p>
            <span className='assistant-name k-display-flex k-align-items-center'>
              Chat With
              {selectedAssistant?.name && assistants.length > 1 && (
                <>
                  <select
                    className='custom-select k-ml-1'
                    value={selectedAssistant.id}
                    onChange={(event) => handleAssistantSelect(event.target.value)}
                  >
                    {assistants.map((assistant, index) => (
                      <option key={assistant.id} value={assistant.id}>
                        {assistant.name}
                      </option>
                    ))}
                  </select>
                </>
              )}
              {selectedAssistant?.name && assistants.length <= 1 && (
                <span className='selected-assistant'>
              {selectedAssistant.name}
                </span>
              )}
              {!sending && (
                <span
                  className='k-button-icon k-font-icon k-i-arrow-rotate-cw-small !k-font-size-xl delete-button k-cursor-pointer reset-button k-ml-1'
                  onClick={handleResetChat}
                />
              )}
            </span>
            </p>
            {(selectedAssistant?.type === 'OpenAI' || selectedAssistant?.type === 'SmartAI') && (
              <div className='streaming-response-switch k-mb-2 k-float-right'>
                <span className='k-mr-2 k-font-size-sm'>Streaming</span>
                <Switch checked={streamingResponse} onChange={handleStreamingResponse} />
              </div>
            )}
          </div>
        </Card>
      )}
      <Card className='chat-box'>
        <div className='bot-chat-window' ref={chatWindowRef}>
          {messages && messages.slice(0).map((message, index) => (
            <div
              key={index}
              className={`message ${message.role === 'user' ? 'user-message' : 'assistant-message'} ${message.type ? ` otherdata ${message.type}` : ''}`}
            >
              {message.type === 'Grid' ? (
                // Render table if the type is Grid
                <>
                  <p>{message?.text}</p>
                  <GridComponent metadata={message?.data?.metadata} values={message?.data?.values} />
                </>
              ) : message.type === 'Pie_Chart' ? (
                // Render pie chart if the type is PieChart
                <>
                  <p>{message?.text}</p>
                  <PieChartComponent metadata={message?.data?.metadata} values={message?.data?.values} />
                </>
              ) : message.type === 'Chart' ? (
                <>
                  <p>{message?.text}</p>
                  <ChartImageComponent imageContent={message?.attachment?.content} imageType={message?.attachment?.type} />
                </>
              ) : (
                // Otherwise, render the message content
                <FormatMessageContent markdownContent={message.text} />
              )}
            </div>
          ))}
          {showPromptOverlay && (
            <div className='prompt-cards'>
              {prompts.map((prompt, index) => (
                <div
                  key={index}
                  className='prompt-card'
                  onClick={() => handlePromptSelection(prompt)}
                >
                  <span>{prompt}</span>
                  <button
                    className='delete-button k-float-right k-cursor-pointer'
                    onClick={() => handlePromptSelection(prompt)}
                  >
                    <span className='fas fa-paper-plane' />
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className='bot-message-input'>
          <input
            type='text'
            value={newMessage}
            onKeyPress={handleKeyPress}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder='Type your message...'
          />
          <Button
            className='ai-button-primary'
            onClick={() => {
              handleMessageSend();
            }}
            disabled={sending}
          >
            {!sending ? <span className='fas fa-paper-plane k-mb-1 k-ml-1' /> :
              <span className='k-text-white'>...</span>}
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default SmartBot;
